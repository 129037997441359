<template>
  <div>
    <v-container fluid>
      <v-row class="mx-auto mt-5 pb-5">
        <v-col cols="12">
          <h1>
            <router-link :to="{ name: 'AssessmentCenter' }"
              >Assessment center</router-link
            >
            | <span class="title"> Booked Vehicles</span>
          </h1>
        </v-col>
      </v-row>
      <v-container>
        <v-row class="mt-7">
          <app-add-vehicle />
          <v-col class="mx-auto" cols="12" xl="11">
            <app-booked-vehicles />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    appBookedVehicles: () =>
      import('@/components/assessment-center/booking/bookedVehiclesTable'),
    appAddVehicle: () =>
      import('@/components/assessment-center/booking/addVehicle'),
  },
  // props: {
  //   source: String,
  // },
  // data: () => ({
  //   dialog: false,
  //   drawer: null,
  // }),
}
</script>
